<template>
  <div class="game-container">
    <LearnReview
      :imgList="imgList"
      :wordsList="wordsList"
      :sentenceList="sentenceList"
      @deleteWords="handleDeleteWords"
    />
  </div>
</template>

<script>
import LearnReview from "@/components/Course/GamePage/LearnReviewGame";
export default {
  data() {
    return {
      imgList: [
        {
          id: 1,
          imgUrl: require("@/assets/img/18-Blue-review/lesson-10-dian-yellow.svg"),
          show: true,
          type: 3,
        },
        {
          id: 2,
          imgUrl: require("@/assets/img/18-Blue-review/lesson-10-hengpie-yellow.svg"),
          show: true,
          type: 3,
        },
        {
          id: 3,
          imgUrl: require("@/assets/img/18-Blue-review/lesson-10-ma-white.svg"),
          show: true,
          type: 3,
        },
      ],
      wordsList: [
        {
          id: 1,
          name: "wǒ",
          namePin: "我",
          show: true,
          type: 1,
        },
        {
          id: 2,
          name: "ài",
          namePin: "爱",
          show: true,
          type: 1,
        },
        {
          id: 3,
          name: "bàba",
          namePin: "爸爸",
          show: true,
          type: 1,
        },
        {
          id: 4,
          name: "māma",
          namePin: "妈妈",
          show: true,
          type: 1,
        },
        {
          id: 5,
          name: "jiā",
          namePin: "家",
          show: true,
          type: 1,
        },
      ],
      sentenceList: [
        {
          id: 1,
          name: "Wǒ ài wǒ jiā.",
          namePin: "我爱我家。",
          show: true,
          type: 2,
        },
      ],
    };
  },
  components: {
    LearnReview,
  },
  methods: {
    handleDeleteWords({ type, id }) {
      if (type === 1) {
        this.wordsList[id - 1].show = false;
      } else if (type === 2) {
        this.sentenceList[id - 1].show = false;
      } else {
        this.imgList[id - 1].show = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
