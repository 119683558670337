<template>
  <div class="recognition-page-heng-container">
    <RecognitionStrokePage
      :backgroundImage="bgImg"
      :strokeObj="strokeDetail"
    />
  </div>
</template>

<script>
import RecognitionStrokePage from "@/components/Course/CoursePage/RecognitionStrokePage";

export default {
  components: {
    RecognitionStrokePage,
  },
  data() {
    return {
      bgImg: require("@/assets/img/15-Hanzi-strokes/lesson-10-dian-image.svg"),
      strokeDetail: {
        pinName: "diǎn",
        chineseName: "点",
        strokeImg: require("@/assets/img/15-Hanzi-strokes/lesson-10-dian-red-border.svg"),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-heng-container {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
